import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import { calculateTimeDiff, statusToColors, statusToIcon } from '../../../../ helpers/helpers.ts';
import { ReactComponent as IconChevronDown } from '../../../../assets/icon-chevron-down.svg';
import ImageModal from '../../../../components/image-modal/ImageModal.tsx';
import { ProcessingStep } from '../../../../models/models.ts';
import { S3ImageToURL } from '../../../../store/store.ts';
import s from './api-result-step.module.scss';

interface Props {
  step: ProcessingStep;
}

const APIResultStep: React.FC<Props> = ({ step }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState({});

  const Icon = statusToIcon(step.status);
  const getS3Image = async (url: string) => {
    if (imageUrls[url]) return;
    const data = await S3ImageToURL(url);
    setImageUrls((prevUrls) => ({ ...prevUrls, [url]: data }));
  };
  // Recursive function to render metadata
  const renderMetadata = (data) => {
    if (Array.isArray(data)) {
      if (data.length === 1) {
        return renderMetadata(data[0]);
      }
      return (
        <div className={s.meta_row_list}>
          {data.map((item, index) => (
            <div key={index}>{typeof item === 'object' ? renderMetadata(item) : item}</div>
          ))}
        </div>
      );
    } else if (typeof data === 'object') {
      return (
        <div className={s.meta_row}>
          {Object.entries(data).map(([key, value]) => (
            <div className={s.meta_row_item} key={key}>
              <strong>{key}:</strong>
              {typeof value === 'string' && value.startsWith('s3://') ? (
                <ImageModal imgSrc={imageUrls[value]}>
                  <img
                    onLoad={() => getS3Image(value)}
                    src={imageUrls[value] ?? 'https://placehold.co/150?text=Loading'}
                    alt={key}
                    width={150}
                  />
                </ImageModal>
              ) : typeof value === 'object' ? (
                renderMetadata(value)
              ) : (
                (value as any).toString()
              )}
            </div>
          ))}
        </div>
      );
    } else {
      return data;
    }
  };

  const renderTags = (data: { [s: string]: string }) => {
    return (
      <div className={s.meta_row_list}>
        {Object.entries(data).map(([key, value]) => (
          <div key={key}>
            <strong>{key}</strong>: {value}
          </div>
        ))}
      </div>
    );
  };
  const timeDiff = useMemo(() => {
    if (
      step.startDatetime &&
      step.startDatetime.getTime() > 0 &&
      step?.stopDatetime &&
      step?.stopDatetime.getTime() > 0
    )
      return calculateTimeDiff(step.startDatetime, step.stopDatetime);
  }, [step]);

  return (
    <div className={clsx(s.wrapper, { [s.wrapper__open]: isOpen })}>
      <div className={s.header} onClick={() => setIsOpen(!isOpen)}>
        <h3>{step.name}</h3>
        <div>
          {<Icon color={statusToColors(step.status).color} />}
          <span>{step.description}</span>
        </div>

        {timeDiff && (
          <div className={s.period}>
            <span>Duration: </span>
            <span>{timeDiff}s</span>
          </div>
        )}

        <button>
          <IconChevronDown />
        </button>
      </div>
      <div className={s.content}>
        {renderMetadata(step.metadata)}
        {renderTags(step.tags)}
      </div>
    </div>
  );
};

export default APIResultStep;
