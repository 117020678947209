import React, { useMemo } from 'react';

import s from '../../routes/processing-jobs/processing-jobs.module.scss';

interface Props {
  positions: { key?: number; name: string }[];
  currentPosition?: number;
  handleChange: (key?: number) => void;
}

const Toggle: React.FC<Props> = ({ positions, currentPosition, handleChange }) => {
  const togglePositionStyle = useMemo(() => {
    if (!currentPosition) {
      return {};
    }
    return {
      transform: `translateX(${currentPosition * 100}%)`,
    };
  }, [currentPosition]);

  return (
    <div className={s.toggles}>
      <div className={s.active} style={togglePositionStyle} />
      {positions.map((position) => (
        <div key={position.key} className={s.toggle} onClick={() => handleChange(position.key)}>
          {position.name}
        </div>
      ))}
    </div>
  );
};

export default Toggle;
