import 'reactflow/dist/style.css';

import { Ring } from '@uiball/loaders';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Controls, Node, ReactFlow, useReactFlow } from 'reactflow';

import { formatDate } from '../../ helpers/helpers.ts';
import { ReactComponent as IconBack } from '../../assets/icon-back.svg';
import g from '../../shared.module.scss';
import { useDataStore } from '../../store/store.ts';
import s from '../api-result-details/api-result-details.module.scss';
import r from '../processing-jobs/processing-jobs.module.scss';
import ConfusionMatrix from './ConfusionMatrix.tsx';
import ModelStatistics from './ModelStatistics.tsx';

interface Props {}

const AIModelDetails: React.FC<Props> = () => {
  const models = useDataStore((state) => state.models);
  const [envInfo] = useDataStore((state) => [state.envInfo]);
  const { setCenter, zoomTo, viewportInitialized } = useReactFlow();
  const [storeModel, fetchModel, fetchModelLineage, modelLineage, setModelLineage] = useDataStore(
    (state) => [
      state.model,
      state.fetchModel,
      state.fetchModelLineage,
      state.modelLineage,
      state.setModelLineage,
      state.envInfo,
    ],
  );
  const { id } = useParams();
  const navigate = useNavigate();

  const model = useMemo(() => {
    if (models && models.length > 0) {
      return models.find((m) => m.id === id);
    } else if (storeModel) {
      return storeModel;
    } else if (id) {
      fetchModel(id);
    }
  }, [models, storeModel, id]);

  useEffect(() => {
    if (id) {
      fetchModelLineage(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      setModelLineage(null);
    };
  }, [setModelLineage]);

  const [selectedNode, setSelectedNode] = useState<Node | null>(null);

  useEffect(() => {
    if (viewportInitialized) {
      setCenter(570, 25);
      zoomTo(1);
    }
  }, [viewportInitialized]);

  const handleRetrainModel = async () => {
    if (!model) return;

    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    let apiUrl = `https://api.${envInfo.env}.diagnostics.ml6.eu/v1/models`;
    if (envInfo.env === 'prd') apiUrl = `https://api.diagnostics.ml6.eu/v1/models`;
    const fullUrl = `${apiUrl}/${encodeURIComponent(atob(model.id))}/train`;
    await fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        alert(
          'Model training started. It may take a few hours before the new model version is visible.',
        );
      })
      .catch((err) => {
        console.error(err);
        alert('Unable to retrain model.');
      });
  };

  const handleDeployModel = async () => {
    if (!model) return;

    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    let apiUrl = `https://api.${envInfo.env}.diagnostics.ml6.eu/v1/models`;
    if (envInfo.env === 'prd') apiUrl = `https://api.diagnostics.ml6.eu/v1/models`;
    const fullUrl = `${apiUrl}/${encodeURIComponent(atob(model.id))}/deploy`;
    await fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        alert('Model deployed. It may take a minute before the model is available.');
      })
      .catch((err) => {
        console.error(err);
        alert('Unable to deploy model.');
      });
  };

  const handleDownloadModel = async () => {
    if (!model) return;

    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    let apiUrl = `https://api.${envInfo.env}.diagnostics.ml6.eu/v1/models`;
    if (envInfo.env === 'prd') apiUrl = `https://api.diagnostics.ml6.eu/v1/models`;
    const fullUrl = `${apiUrl}/${encodeURIComponent(atob(model.id))}/data`;
    await fetch(fullUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((output) => {
        window.open(output['preSignedUrl'], '_blank');
      })
      .catch((err) => {
        console.error(err);
        alert('Unable to download model data for this model.');
      });
  };

  return (
    <div className={g.page}>
      <div className={clsx(g.pageHeader)}>
        <div onClick={() => navigate('/ai-models')} className={g.pageHeaderNav}>
          <IconBack />
        </div>
        {model?.packageVersion && (
          <div className={clsx(r.tag, r.tag_default)} style={{ marginRight: 10 }}>
            v{model.packageVersion}
          </div>
        )}

        <h1> {model?.name ?? 'Loading'} </h1>
        {model && (
          <div className={g.pageHeaderButtons}>
            <button onClick={handleRetrainModel} className={r.table_button}>
              Retrain Model
            </button>
            <button onClick={handleDeployModel} className={r.table_button}>
              Deploy Model
            </button>
            <button onClick={handleDownloadModel} className={r.table_button}>
              Download Model
            </button>
          </div>
        )}
      </div>
      {model && (
        <div className={g.pageBody}>
          <div className={s.details}>
            <div className={s.info}>
              <span>Training Date: {formatDate(model.creationTime)}</span>
              <span className={s.status}>
                Status: <b>{model.status?.status ?? 'Unknown'}</b>
              </span>
              <div className={s.metrics}>
                {Object.entries(model.metrics ?? {}).map(([k, v]) => {
                  return (
                    <div key={k}>
                      {k}: <b>{v} %</b>
                    </div>
                  );
                })}
              </div>
            </div>
            {model.confusionMatrix && model.classIdToName && (
              <ConfusionMatrix classIdToName={model.classIdToName} matrix={model.confusionMatrix} />
            )}
          </div>
          <div className={s.modelDetails}>
            {model?.confusionMatrix?.statistics && (
              <ModelStatistics
                classIdToName={model.classIdToName}
                stats={model.confusionMatrix.statistics}
              />
            )}

            <h2>Model Lineage</h2>
            <div className={s.chartWrapper}>
              {!modelLineage && <Ring color={'#FF913AFF'} />}
              {modelLineage && (
                <ReactFlow
                  onNodeClick={(_, node) => {
                    setSelectedNode(node);
                  }}
                  nodes={modelLineage?.nodes}
                  edges={modelLineage?.edges}
                >
                  <Controls />
                </ReactFlow>
              )}
            </div>
            {selectedNode && (
              <div className={s.chartDetails}>
                <div className={s.info}>
                  <span>
                    Name: <b>{selectedNode.data.name}</b>
                  </span>
                  <span>
                    Description: <b>{selectedNode.data.description}</b>
                  </span>
                  <span>
                    Internal Name: <b>{selectedNode.data.info.display_name ?? '/'}</b>
                  </span>
                  <span>
                    Source: <b>{selectedNode.data.info.source.source_uri ?? '/'}</b>
                  </span>
                  <span>
                    Last Modified:{' '}
                    <b>{new Date(selectedNode.data.info.last_modified_time).toString()}</b>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AIModelDetails;
