import { useAuthenticator } from '@aws-amplify/ui-react';
import { AppSyncClient } from '@aws-sdk/client-appsync';
import { CloudWatchClient } from '@aws-sdk/client-cloudwatch';
import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { S3Client } from '@aws-sdk/client-s3';
import { STSClient } from '@aws-sdk/client-sts';
import { DynamoDBDocumentClient } from '@aws-sdk/lib-dynamodb';
import { Waveform } from '@uiball/loaders';
import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { shallow } from 'zustand/shallow';

import awsConfig from '../../aws/aws-exports.js';
import { useClientStore } from '../../store/clientStore.ts';
import { useAuthStore } from '../../store/store.ts';

interface Props {
  children: any;
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const setAuthenticatedUser = useAuthStore((state) => state.setAuthenticatedUser);
  const [
    dynamoDbClient,
    setDynamoDbClient,
    mainS3Client,
    setMainS3Client,
    setCloudWatchClient,
    setAppSyncClient,
    setStsClient,
  ] = useClientStore(
    (state) => [
      state.dynamoDbClient,
      state.setDynamoDbClient,
      state.mainS3Client,
      state.setMainS3Client,
      state.setCloudWatchClient,
      state.setAppSyncClient,
      state.setSTSClient,
    ],
    shallow,
  );
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    const func = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setAuthenticatedUser(user);
      const credentials = await Auth.currentCredentials();
      const dynamoDbClient = new DynamoDBClient({
        credentials: Auth.essentialCredentials(credentials),
        region: awsConfig.Auth.region,
      });
      const stsClient = new STSClient({
        credentials: Auth.essentialCredentials(credentials),
        region: awsConfig.Auth.region,
      });
      const appSyncClient = new AppSyncClient({
        credentials: Auth.essentialCredentials(credentials),
        region: awsConfig.Auth.region,
      });
      const cloudWatchClient = new CloudWatchClient({
        credentials: Auth.essentialCredentials(credentials),
        region: awsConfig.Auth.region,
      });
      const docClient = DynamoDBDocumentClient.from(dynamoDbClient);
      const s3Client = new S3Client({
        region: awsConfig.Auth.region,
        credentials: Auth.essentialCredentials(credentials),
      });
      setCloudWatchClient(cloudWatchClient);
      setMainS3Client(s3Client);
      setDynamoDbClient(docClient);
      setAppSyncClient(appSyncClient);
      setStsClient(stsClient);
    };

    func();
  }, [authStatus]);

  if (authStatus === 'configuring') {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Waveform />
      </div>
    );
  }
  if (authStatus !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!dynamoDbClient || !mainS3Client) {
    return null;
  }

  return children;
};

export default RequireAuth;
