export const formatDate = (date: Date, length = 'long') => {
  const time = date.toLocaleTimeString('be-NL', { hour12: false });

  if (length === 'short') {
    return `${date.toLocaleDateString('be-NL', {
      month: 'numeric',
      year: '2-digit',
      day: 'numeric',
    })} ${time}`;
  }
  return `${date.toLocaleDateString('be-NL', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })} ${time}`;
};

import { Ring } from '@uiball/loaders';
import { Edge, Node, Position } from 'reactflow';

import { ReactComponent as IconCreated } from '../assets/icon-created.svg';
import { ReactComponent as IconFail } from '../assets/icon-fail.svg';
import { ReactComponent as IconSuccess } from '../assets/icon-success.svg';
import { Model } from '../models/models.ts';

export const statusToIcon = (status: string) => {
  switch (status) {
    case 'CREATED':
      return IconCreated;
    case 'RUNNING':
      return Ring;
    case 'FINISHED':
      return IconSuccess;
    case 'FAILED':
      return IconFail;
    default:
      return Ring;
  }
};
export const truncate = (nr?: number) => {
  return nr?.toFixed(2);
};

export const statusToColors = (status: string): { fade: string; color: string } => {
  switch (status) {
    case 'CREATED':
      return { fade: '#d7e6ff', color: '#3f98f6' };
    case 'RUNNING':
      return { fade: '#ECEFF1', color: '#4D5770' };
    case 'FINISHED':
      return { fade: '#DFF1DA', color: '#66c14d' };
    case 'FAILED':
      return { fade: '#f1dada', color: '#df3131' };
    default:
      return { fade: '#dae1f1', color: '#3f98f6' };
  }
};
export const calculateTimeDiff = (startTime: Date, endTime: Date) => {
  if (startTime.getTime() > endTime.getTime()) {
    return 0;
  }
  return (endTime.getTime() - startTime.getTime()) / 1000;
};

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const nodeTypeColorMap: { [key: string]: string } = {
  DataSet: '#4b93da', // Light blue
  ProcessingJob: '#72ca4c', // Light green
  TrainingJob: '#f45ba0', // Light orange
  Model: '#f6b144', // Light yellow
  ModelDeployment: '#c14cc1', // Light purple
  // Add more types and colors as needed
};

const positionNodes = (nodes, edges) => {
  const nodesById = new Map<string, any>(nodes.map((node) => [node.id, { ...node }]));
  const levels: Map<number, any[]> = new Map();

  const assignLevels = (nodeId: string, level: number) => {
    const node = nodesById.get(nodeId);
    if (!node || (node.level !== undefined && node.level >= level)) return;

    node.level = level;
    levels.set(level, [...(levels.get(level) || []), node]);

    const outgoingEdges = edges.filter((edge) => edge.source === nodeId);
    outgoingEdges.forEach((edge) => assignLevels(edge.target, level + 1));
  };

  nodes.forEach((node) => {
    if (!edges.some((edge) => edge.target === node.id)) {
      assignLevels(node.id, 0);
    }
  });

  const nodeElements: Node[] = [];
  const edgeElements: Edge[] = [];
  const levelHeight = 100;
  const nodeWidth = 275;

  levels.forEach((levelNodes, level) => {
    const startY = ((levelNodes.length - 1) * levelHeight) / -2;
    levelNodes.forEach((node, index) => {
      const position = { x: level * nodeWidth, y: startY + index * levelHeight };
      nodeElements.push({
        ...node,
        position,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        draggable: false,
      } as Node);
    });
  });

  edges.forEach((edge, index) => {
    edgeElements.push({ id: `e${index}`, ...edge, animated: true, style: { stroke: '#000' } });
  });

  return { nodes: nodeElements, edges: edgeElements };
};

export const convertLineage = (jsonData: any) => {
  const nodes: any[] = jsonData.nodes.map((node) => ({
    id: node.id,
    data: { ...node, label: `${node.name}: ${node.description}`, description: node.description },
    style: { border: `1px solid ${nodeTypeColorMap[node.name]}`, padding: 15, borderRadius: 5 },
  }));

  const edges: any[] = jsonData.edges.map((edge) => ({
    source: edge.source,
    target: edge.target,
    label: edge.association.replace(/([A-Z])/g, ' $1'),
  }));

  return positionNodes(nodes, edges);
};

export const parseModel = (model: any): Model => {
  const metrics = model.metrics;
  let formattedMetrics;
  if (metrics) {
    Object.entries(metrics).forEach(([key, value]) => {
      formattedMetrics = {
        ...formattedMetrics,
        [key]: typeof value === 'number' ? parseFloat((value * 100).toFixed(2)) : value,
      };
    });
  }
  return {
    id: btoa(model.id),
    name: model.name,
    arn: model.model_arn,
    creationTime: new Date(model['creationTime']),
    metrics: formattedMetrics,
    status: model.status,
    packageGroupName: model.model_package_group_name,
    packageVersion: model.version,
    confusionMatrix: model['confusionMatrix'],
    classIdToName: model['classIdToName'],
  };
};
