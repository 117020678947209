import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ReactComponent as Animation } from '../../assets/animation.svg';
import { ReactComponent as LogoML6 } from '../../assets/logo-ml6.svg';
import { useDataStore } from '../../store/store.ts';
import s from './login.module.scss';

interface Props {}

const Login: React.FC<Props> = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState();
  const envInfo = useDataStore((state) => state.envInfo);

  const redirectToSaml = () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito }).catch((err) => {
      console.debug('Error signing in', err);
      setError(err);
    });
  };

  useEffect(() => {
    if (envInfo.tenantId === 'mmv-demo') {
      emailLogin(null);
    }
  }, [envInfo]);

  const emailLogin = (ev) => {
    let e = email;
    let p = password;
    if (envInfo.tenantId === 'mmv-demo') {
      e = 'admin';
      switch (envInfo.env) {
        case 'dev':
          p = '=QgJIcTnyvy5dJ{J';
          break;
        case 'tst':
          p = 't8k3V+9OTTtC1BJe';
          break;
        case 'prd':
          p = 'X?(vUQ4rT0vkLPeZ';
          break;
      }
    }

    ev?.preventDefault();
    Auth.signIn({ username: e, password: p })
      .then(() => {
        Auth.currentAuthenticatedUser().then(() => {
          navigate('/');
        });
      })
      .catch((err) => {
        setError(err);
      });
  };

  const from: string = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <main className={s.container}>
      <Animation className={s.background} />
      <div className={s.logo}>
        <LogoML6 className={s.logo} />
        <span>Vision AI</span>
      </div>
      {(!envInfo || envInfo?.tenantId != 'mmv-demo') && (
        <div className={s.inner}>
          <button onClick={redirectToSaml}>Log in with SAML</button>
          <form onSubmit={emailLogin}>
            <input required type="text" onChange={(e) => setEmail(e.target.value)} />
            <input required type="password" onChange={(e) => setPassword(e.target.value)} />
            {error && <span>Something went wrong, please try again</span>}
            <button type={'submit'}>Log in with Email</button>
          </form>
        </div>
      )}
    </main>
  );
};

export default Login;
