import { AppSyncClient } from '@aws-sdk/client-appsync';
import { CloudWatchClient } from '@aws-sdk/client-cloudwatch';
import { S3Client } from '@aws-sdk/client-s3';
import { STSClient } from '@aws-sdk/client-sts';
import { DynamoDBDocumentClient } from '@aws-sdk/lib-dynamodb';
import { create } from 'zustand';

type ClientState = {
  dynamoDbClient?: DynamoDBDocumentClient | null;
  setDynamoDbClient: (client: DynamoDBDocumentClient) => void;
  mainS3Client?: S3Client | null;
  setMainS3Client: (client: S3Client) => void;
  cloudWatchClient?: CloudWatchClient | null;
  setCloudWatchClient: (client: CloudWatchClient) => void;
  appSyncClient?: AppSyncClient | null;
  setAppSyncClient: (client: AppSyncClient) => void;
  stsClient?: STSClient | null;
  setSTSClient: (client: STSClient) => void;
};

export const useClientStore = create<ClientState>((set) => ({
  dynamoDbClient: null,
  mainS3Client: null,
  cloudWatchClient: null,
  appSyncClient: null,
  stsClient: null,
  setDynamoDbClient: (client: DynamoDBDocumentClient) => set({ dynamoDbClient: client }),
  setMainS3Client: (client: S3Client) => set({ mainS3Client: client }),
  setCloudWatchClient: (client: CloudWatchClient) => set({ cloudWatchClient: client }),
  setAppSyncClient: (client: AppSyncClient) => set({ appSyncClient: client }),
  setSTSClient: (client: STSClient) => set({ stsClient: client }),
}));
