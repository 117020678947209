import { GetCallerIdentityCommand } from '@aws-sdk/client-sts';
import { Auth } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as IconUpload } from '../../../../assets/icon-upload.svg';
import { ReactComponent as LogoML6 } from '../../../../assets/logo-ml6.svg';
import ProgressBar from '../../../../components/progress-bar/ProgressBar';
import { useClientStore } from '../../../../store/clientStore.ts';
import { S3ImageToURL, useDataStore } from '../../../../store/store.ts';
import s from './demo-upload.module.scss';

interface Props {}
let interval;

const DemoUpload: React.FC<Props> = () => {
  const [setDemoData, demoImage, setDemoImage, fetchActiveJob, activeJob, envInfo] = useDataStore(
    (state) => [
      state.setDemoData,
      state.demoImage,
      state.setDemoImage,
      state.fetchActiveJob,
      state.activeJob,
      state.envInfo,
    ],
  );
  const navigate = useNavigate();
  const stsClient = useClientStore((state) => state.stsClient);

  const [userInfo, setUserInfo] = useState({ company: '', email: '', submit: false });
  const imageRef = useRef<any>();
  const [demoImageFiles, setDemoImageFiles] = useState<Record<string, string>>({});

  const [jobId, setJobId] = useState(null);
  const [demoImagePaths, setDemoImagePaths] = useState<string[]>([]);

  useEffect(() => {
    const func = async () => {
      if (stsClient) {
        const command = new GetCallerIdentityCommand({});
        const response = await stsClient.send(command);
        if (response.Account)
          setDemoImagePaths([
            `s3://${response.Account}-mmv-demo-example-images/example1.jpg`,
            `s3://${response.Account}-mmv-demo-example-images/example2.jpg`,
            `s3://${response.Account}-mmv-demo-example-images/example3.jpg`,
          ]);
      }
    };
    func();
  }, [stsClient]);

  useEffect(() => {
    const func = async () => {
      for (const s3Path of demoImagePaths) {
        const imgUrl = (await S3ImageToURL(s3Path)) as string;
        setDemoImageFiles((prev) => {
          const cl = { ...prev };
          cl[s3Path] = imgUrl;
          return cl;
        });
      }
    };
    func();
  }, [demoImagePaths]);

  useEffect(() => {
    const check = () => {
      if (jobId) fetchActiveJob(jobId);
    };

    if (jobId) interval = setInterval(check, 1000);
    return () => {
      if (interval && (!jobId || activeJob?.status === 'FINISHED')) {
        clearInterval(interval);
      }
    };
  }, [jobId]);

  useEffect(() => {
    if (activeJob?.status === 'FINISHED' && interval) {
      const step = activeJob.steps.find((e) => e.name === 'Image analysis');
      if (!step) {
        return;
      }
      setDemoData(step.metadata);
      navigate(`/results/${jobId}`);
      clearInterval(interval);
    }
  }, [activeJob?.status]);

  useEffect(() => {
    if (activeJob?.status === 'FAILED' && interval) {
      setDemoImage(null);
      clearInterval(interval);
    }
  }, [activeJob?.status]);

  const handleImageChange = async (file: File | null, imageUrl: string | null) => {
    if (!file && !imageUrl) {
      return;
    }
    let temp: any = { file: null, url: null };
    if (imageUrl) {
      const response = await fetch(imageUrl, {
        method: 'GET',
      });
      const blob = await response.blob();
      const file = new File([blob], 'example.jpg', { type: blob.type });
      temp = { file, url: imageUrl };
    } else if (file) {
      if (file.size > 5242880) {
        alert('Maximum file size of 5MB exceeded!');
        return;
      }
      const url = URL.createObjectURL(file);
      setDemoImage({ file, url });
      temp = { file, url };
    }
    setDemoImage(temp);
    const formData = new FormData();
    if (temp?.file) formData.append(`images`, temp.file);

    let apiUrl = `https://api.${envInfo.env}.diagnostics.ml6.eu/v1/jobs`;
    if (envInfo.env === 'prd') apiUrl = `https://api.diagnostics.ml6.eu/v1/jobs`;
    const url = new URL(apiUrl);
    const observation = JSON.stringify({
      publisherId: 'demo-frontend',
      metadata: userInfo,
    });
    formData.append('invocationMode', 'POLLING');
    formData.append('observation', observation);

    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    setJobId(data['id']);
  };
  useEffect(() => {
    const item = localStorage.getItem('userInfo');
    if (item) {
      const ui = JSON.parse(item);
      if (ui.email) {
        navigate(`/`);
      }
      setUserInfo(JSON.parse(item));
    }
  }, []);

  return (
    <div className={s.body}>
      <a
        className={s.ml6}
        href="https://www.ml6.eu/solutions/vision-ai"
        target="_blank"
        rel="noreferrer"
      >
        <LogoML6 />
      </a>
      {demoImage && (
        <div className={s.overlay}>
          <div className={s.cover}>
            <span>Processing</span>
            <ProgressBar />
            <p style={{ top: '20px', fontSize: 'medium' }}>Did you know?</p>
            <p>
              Our typical implementation timeline takes just 8 weeks from setup until the actual
              go-live. <br /> <br />
              Our solution is used in many different industries: Manufacturing, Pharma, Biotech,
              Material science...
            </p>
            <button onClick={() => setDemoImage(null)}>Cancel</button>
          </div>
          <img src={demoImage.url} alt="" />
        </div>
      )}
      {!demoImage && (
        <>
          <div className={s.header}>
            <h1>Vision AI Quality Inspection</h1>
            <p>Upload an image and experience the future of quality control today!</p>
          </div>

          {!userInfo.submit && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setUserInfo({ ...userInfo, submit: true });
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, submit: true }));
                navigate(`/`);
              }}
              className={s.form}
            >
              <h2>Enter your details to begin</h2>

              <input
                required
                type="text"
                placeholder="Company Name"
                value={userInfo.company}
                onChange={(e) => setUserInfo({ ...userInfo, company: e.target.value })}
              />
              <input
                required
                type="email"
                placeholder="Email Address"
                value={userInfo.email}
                onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
              />
              <button type={'submit'}>Submit</button>
            </form>
          )}

          {userInfo.submit && (
            <div className={s.content}>
              <div className={s.upload}>
                <button
                  onClick={() => {
                    if (imageRef.current) {
                      const buttonElement = imageRef.current as HTMLButtonElement;
                      buttonElement.click();
                    }
                  }}
                >
                  <input
                    accept="image/png ,image/jpeg, image/jpg"
                    ref={imageRef}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={(e) =>
                      handleImageChange(e.target.files ? e.target.files[0] : null, null)
                    }
                  />
                  <IconUpload /> Upload File
                </button>
              </div>
              <div className={s.select}>
                <h2>Or use any of the example images below</h2>
                <div className={s.items}>
                  {demoImagePaths.map((path, index) => {
                    const url = demoImageFiles[path];
                    return (
                      <div
                        key={index}
                        onClick={() => handleImageChange(null, url)}
                        style={{
                          animationDelay: `${index * 0.5}s`,
                          animationDuration: 5 + Math.random() * 5 + 's',
                        }}
                        className={s.image}
                      >
                        <img src={url} alt="" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          <div className={s.privacy}>
            <a target="_blank" href="https://www.ml6.eu/privacy-notice" rel="noreferrer">
              Privacy Notice
            </a>
            |
            <a target="_blank" href="https://www.ml6.eu/contact" rel="noreferrer">
              Contact Us
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default DemoUpload;
