import { Ring } from '@uiball/loaders';
import React, { CSSProperties, useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { LoadingState, Model } from '../../models/models.ts';
import g from '../../shared.module.scss';
import { useDataStore } from '../../store/store.ts';
import s from '../processing-jobs/processing-jobs.module.scss';
import AIModelRow from './AIModelRow.tsx';

interface Props {}
export interface ModelGroup {
  name: string;
  models: Model[];
}

export enum Metrics {
  precision = 'Precision',
  recall = 'Recall',
  accuracy = 'Accuracy',
}

const AIModels: React.FC<Props> = () => {
  const [models, fetchModels, modelsLoadingState] = useDataStore(
    useShallow((state) => [state.models, state.fetchModels, state.modelsLoadingState]),
  );

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  const modelGroups = useMemo(() => {
    const groups: ModelGroup[] = [];
    for (const model of models) {
      const group = groups.find((g) => g.name === model.name);
      if (group) {
        group.models.push(model);
      } else {
        groups.push({ name: model.name, models: [model] });
      }
    }
    return groups;
  }, [models]);
  const tableStyle = {
    gridTemplateColumns: 'auto 3fr 2fr 1fr 1fr 1fr 1fr 1fr',
  } as CSSProperties;

  return (
    <div className={g.page}>
      <div className={g.pageHeader}>
        <h1>AI Models</h1>
      </div>
      <div className={g.pageBody}>
        {modelsLoadingState === LoadingState.LOADING && (
          <div className={s.loading_wrapper}>
            <Ring color={'#FF913AFF'} />
          </div>
        )}
        {modelsLoadingState === LoadingState.ERROR && (
          <div className={s.loading_wrapper}>
            Something went wrong, reload the page and try again.
          </div>
        )}
        {modelsLoadingState === LoadingState.SUCCESS && (
          <div className={s.table} style={tableStyle}>
            <div className={s.tableHeader}>
              <div className={s.column}></div>
              <div className={s.column}>Name</div>
              <div className={s.column}>Training Date</div>
              <div className={s.column}>Status</div>
              <div className={s.column}>Accuracy</div>
              <div className={s.column}>Recall</div>
              <div className={s.column}>Precision</div>
              <div className={s.column}></div>
            </div>
            {models.length === 0 && <div className={s.noResults}>No results Found</div>}
            {modelGroups.map((group) => {
              return <AIModelRow key={group.name} modelGroup={group} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AIModels;
