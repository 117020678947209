import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { calculateTimeDiff, statusToColors, statusToIcon } from '../../../../ helpers/helpers.ts';
import missingImageIcon from '../../../../assets/icon-image-missing.svg';
import { ProcessingJob } from '../../../../models/models.ts';
import { useDataStore } from '../../../../store/store.ts';
import r from '../../processing-jobs.module.scss';

interface Props {
  job: ProcessingJob;
}

const JobRow: React.FC<Props> = ({ job }) => {
  const nav = useNavigate();
  const [observationImages, observations, fetchObservationImageUrl, fetchObservationById] =
    useDataStore(
      (state) => [
        state.observationImages,
        state.observations,
        state.fetchObservationImageUrl,
        state.fetchObservationById,
      ],
      shallow,
    );

  const activeObservation = useMemo(() => {
    return observations.find((o) => o.id === job.observationId);
  }, [job, observations]);

  useEffect(() => {
    if (activeObservation == null) fetchObservationById(job.observationId);
  }, [activeObservation, job]);

  useEffect(() => {
    if (activeObservation)
      for (const image of activeObservation.images) {
        fetchObservationImageUrl(image);
      }
  }, [job, activeObservation]);

  const Icon = statusToIcon(job?.status ?? 'unknown');

  const tagStyle = useMemo(() => {
    return {
      width: 100,
      height: 30,
      backgroundColor: statusToColors(job?.status ?? 'unknown').fade,
      color: statusToColors(job?.status ?? 'unknown').color,
    };
  }, [job.status]);

  const isPrevSuccess = (currentIndex: number) => {
    return currentIndex === 0 || job.steps[currentIndex - 1].status === 'FINISHED';
  };

  const renderImages = (image, index) => {
    const mapped = observationImages[image.url];
    return (
      <img
        key={activeObservation?.id + index}
        src={mapped}
        alt=""
        onError={(e) => (e.currentTarget.src = missingImageIcon)}
      />
    );
  };

  return (
    <div className={r.tableRow} onClick={() => nav(`/results/${job.id}`)}>
      <div className={clsx(r.column)}>
        {activeObservation?.images.map((image, i) => {
          return renderImages(image, i);
        })}
      </div>
      <div className={clsx(r.column)}>{job.creationDatetime.toLocaleString()}</div>

      <div className={clsx(r.column)}>
        <div className={r.tag} style={tagStyle}>
          {Icon ? <Icon color={tagStyle.color} /> : <div />}
          <span style={{ textTransform: 'capitalize' }}>{job.status.toLowerCase()}</span>
        </div>
      </div>
      <div className={clsx(r.column)}>
        <div className={r.steps}>
          <div className={clsx(r.connector, r.background)} />
          {job.steps
            .sort((a, b) => a.creationDatetime.getDate() - b.creationDatetime.getDate())
            .map((step, i) => (
              <React.Fragment key={activeObservation?.id + step.name + i}>
                {i > 0 && (
                  <div
                    key={step.name + 'connector'}
                    className={r.connector_segment}
                    style={{
                      left: `${(100 / (job.steps.length - 1)) * (i - 1)}%`,
                      width: `${100 / (job.steps.length - 1)}%`,
                      backgroundColor: isPrevSuccess(i)
                        ? statusToColors(step.status).color
                        : 'transparent',
                    }}
                  />
                )}

                <div
                  key={step.name + 'dot'}
                  className={r.dot}
                  style={{
                    backgroundColor: isPrevSuccess(i)
                      ? statusToColors(step.status).color
                      : '#E3E3E3',
                    color: isPrevSuccess(i) ? statusToColors(step.status).fade : '#E3E3E3',
                    border: `1px solid ${statusToColors(step.status).fade}`,
                  }}
                />
              </React.Fragment>
            ))}
        </div>
      </div>
      <div className={clsx(r.column)}>
        {job.steps
          .reduce((acc, step) => {
            if (step.startDatetime && step.stopDatetime) {
              return acc + calculateTimeDiff(step.startDatetime, step.stopDatetime);
            }
            return acc + 0;
          }, 0)
          .toFixed(3)}
        {' s'}
      </div>
    </div>
  );
};

export default JobRow;
