import clsx from 'clsx';
import React, { useState } from 'react';

import { ReactComponent as IconDown } from '../../../../assets/icon-down.svg';
import s from './demo-results.module.scss';

interface Props {
  data: any;
}

const DemoCard: React.FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const scoreToColor = (score: number) => {
    switch (score) {
      case 5:
        return '#de3939';
      case 4:
        return '#ff6a00';
      case 3:
        return '#b1c401';
      case 2:
        return '#66bf1b';
      case 1:
        return '#0085ff';
    }
  };
  const scoreToName = (score: number) => {
    switch (score) {
      case 5:
        return 'Yes';
      case 4:
        return 'Likely';
      case 3:
        return 'Unlikely';
      case 2:
        return 'No';
      case 1:
        return 'Unsure';
    }
  };
  return (
    <div className={clsx(s.card, { [s.open]: isOpen })}>
      <div className={s.card_top}>
        <div className={s.title}>
          <h3>{data.name}</h3>
          <div className={s.badge} style={{ backgroundColor: scoreToColor(data.score) }}>
            {scoreToName(data.score)}
          </div>
        </div>
        <p>{data.description}</p>
        {data.info && (
          <button onClick={() => setIsOpen(!isOpen)} className={s.arrow}>
            <IconDown />
          </button>
        )}
      </div>
      {data.info && (
        <div onClick={() => setIsOpen(!isOpen)} className={s.card_bottom}>
          <div className={s.row}>
            <span>Position</span>
            <span>3.25s</span>
          </div>
          <div className={s.row}>
            <span>Position</span>
            <span>3.25s</span>
          </div>
          <div className={s.row}>
            <span>Position</span>
            <span>3.25s</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DemoCard;
