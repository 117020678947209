import clsx from 'clsx';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { truncate } from '../../ helpers/helpers.ts';
import { ReactComponent as IconChevronDown } from '../../assets/icon-chevron-down.svg';
import { Model } from '../../models/models.ts';
import s from '../processing-jobs/processing-jobs.module.scss';
import { Metrics, ModelGroup } from './AIModels.tsx';

interface Props {
  modelGroup: ModelGroup;
}

const AIModelRow: React.FC<Props> = ({ modelGroup }) => {
  const [isOpen, setIsOpen] = useState(false);
  const nav = useNavigate();

  const handleNavToDetails = (e, model: Model) => {
    e.stopPropagation();
    e.preventDefault();
    nav('/ai-models/' + model.id);
  };

  const getMaxMetric = (metric) => {
    return modelGroup.models.reduce((acc, model) => {
      const metricValue = model.metrics?.[metric];
      return metricValue > acc ? metricValue : acc;
    }, 0);
  };

  const renderRow = (model, expand = false, child = false) => {
    const accuracy = model.metrics?.[Metrics.accuracy];
    const recall = model.metrics?.[Metrics.recall];
    const precision = model.metrics?.[Metrics.precision];
    const isHighestAccuracy = accuracy === getMaxMetric(Metrics.accuracy);
    const isHighestRecall = recall === getMaxMetric(Metrics.recall);
    const isHighestPrecision = precision === getMaxMetric(Metrics.precision);

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          expand ? setIsOpen(!isOpen) : null;
        }}
        key={model.arn}
        className={clsx(
          s.tableRow,
          { [s.tableRow_no_hover]: !expand },
          { [s.tableRow_active]: isOpen && (expand || child) },
        )}
      >
        <div className={clsx(s.column)}>
          <div className={clsx(s.tag, s.tag_default)}>v{model.packageVersion}</div>
        </div>
        <div className={clsx(s.column)}>{!child && modelGroup.name}</div>
        <div className={clsx(s.column)}>{model.creationTime.toLocaleString()}</div>
        <div className={clsx(s.column)}>{model.status?.status ?? '?'}</div>
        <div className={clsx(s.column, { [s.highest]: isHighestAccuracy && isOpen })}>
          {truncate(accuracy) ?? '?'} %
        </div>
        <div className={clsx(s.column, { [s.highest]: isHighestRecall && isOpen })}>
          {truncate(recall) ?? '?'} %
        </div>
        <div className={clsx(s.column, { [s.highest]: isHighestPrecision && isOpen })}>
          {truncate(precision) ?? '?'} %
        </div>

        <div className={clsx(s.column)}>
          <button onClick={(e) => handleNavToDetails(e, model)} className={s.table_button}>
            Details
          </button>
          {expand && (
            <button>
              <IconChevronDown style={isOpen ? { transform: 'rotate(180deg)' } : {}} />
            </button>
          )}
        </div>
      </div>
    );
  };

  if (modelGroup.models.length <= 1) {
    const latestModel = modelGroup.models[0];
    return renderRow(latestModel);
  } else {
    const latestModel = modelGroup.models.sort((a, b) => b.packageVersion - a.packageVersion)[0];
    const remainingModels = modelGroup.models.filter(
      (m) => m.packageVersion !== latestModel.packageVersion,
    );
    return (
      <>
        {renderRow(latestModel, true)}
        {isOpen && remainingModels.map((model) => renderRow(model, false, true))}
      </>
    );
  }
};

export default AIModelRow;
