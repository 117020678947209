import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { ReactComponent as IconCross } from '../../../../assets/icon-cross.svg';
import { useDataStore } from '../../../../store/store.ts';
import s from './tag-filter.module.scss';

export interface TagFilterValue {
  key?: string;
  value?: string;
}

interface Props {
  handleFilter: (e: TagFilterValue[]) => void;
}

const TagFilter: React.FC<Props> = ({ handleFilter }) => {
  const [tagOptions] = useDataStore((state) => [state.tagFilterOptions]);
  const [isOpen, setIsOpen] = useState(false);
  const [tagName, setTagName] = useState<string>('');
  const [tagValue, setTagValue] = useState<string>('');
  const [filterItems, setFilterItems] = useState<any[]>([]);

  useEffect(() => {
    if (tagOptions && tagOptions.length > 0) {
      setTagName((cur) => {
        return cur == '' ? tagOptions[0] : cur;
      });
    } else {
      setTagName('');
      setTagValue('');
    }
  }, [tagOptions]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterItems([
      { key: tagName != '' ? tagName : undefined, value: tagValue != '' ? tagValue : undefined },
    ]);
    handleFilter([
      { key: tagName != '' ? tagName : undefined, value: tagValue != '' ? tagValue : undefined },
    ]);
    setIsOpen(false);
  };
  const handleClear = () => {
    setTagValue('');
    setTagName('');
    handleFilter([]);
    setFilterItems([]);
  };
  return (
    <div className={s.wrapper}>
      <button onClick={() => setIsOpen(!isOpen)} className={s.wrapperButton}>
        {isOpen ? (
          <>
            <span>Close</span> <IconCross />
          </>
        ) : (
          <span>Filter {filterItems.length > 0 && `(${filterItems.length})`}</span>
        )}
      </button>
      <form onSubmit={handleSubmit} className={clsx(s.container, { [s.container__open]: isOpen })}>
        <div className={s.header}>
          <span>Tag filtering</span>
          <button type={'button'} className={clsx(s.button, s.button__clear)} onClick={handleClear}>
            Clear
          </button>
          <button type={'submit'} className={clsx(s.button)}>
            Filter
          </button>
        </div>
        <div className={s.row}>
          {tagOptions && tagOptions.length > 0 ? (
            <select onChange={(e) => setTagName(e.target.value)} name="tagName" id="0">
              {tagOptions.map((tag) => (
                <option value={tag} key={tag}>
                  {tag}
                </option>
              ))}
            </select>
          ) : (
            <input
              autoComplete="off"
              type="text"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              name="tagName"
              id="0"
              placeholder={'Tag name'}
            />
          )}

          <input
            type="text"
            name="tagValue"
            id="0"
            value={tagValue}
            onChange={(e) => setTagValue(e.target.value)}
            placeholder={'Tag value'}
          />
        </div>
      </form>
    </div>
  );
};

export default TagFilter;
