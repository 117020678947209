const awsConfig = {
  Auth: {
    region: 'eu-west-3',
    userPoolId: '',
    userPoolWebClientId: '',
    identityPoolId: '',
  },
  oauth: {
    redirectSignIn: 'http://localhost:5173',
    redirectSignOut: 'http://localhost:5173',
    domain: '',
    scope: ['openid'],
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint:
    'https://p7okr2tvvvag3pcqviy3m5jofa.appsync-api.eu-west-3.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-3',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default awsConfig;
