import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import './app.scss';
import './index.css';
// Sentry initialization should be imported first!
import './sentry/instrument';

import ReactDOM from 'react-dom/client';

import App from './App.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
