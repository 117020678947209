export interface APIResult {
  id: string;
  date: Date;
  status: string;
  imageURL?: string;
}
export interface Model {
  id: string;
  name: string;
  arn: string;
  creationTime: Date;
  packageGroupName: string;
  packageVersion: number;
  metrics: Record<string, number>;
  status?: {
    status: string;
  };
  confusionMatrix?: any;
  classIdToName?: any;
}
export interface ModelLineage {
  id: string;
}
export type StatusType = 'CREATED' | 'RUNNING' | 'FINISHED' | 'FAILED';
export enum StatusEnum {
  'CREATED',
  'RUNNING',
  'FINISHED',
  'FAILED',
}
export interface ProcessingJob {
  id: string;
  creationDatetime: Date;
  steps: ProcessingStep[];
  observationId: string;
  status: StatusType;
  tags: Record<string, any>;
}
export interface ProcessingStep {
  id: string;
  name: string;
  creationDatetime: Date;
  startDatetime?: Date;
  stopDatetime?: Date;
  description?: string;
  status: StatusType;
  metadata: Record<string, string>;
  tags: Record<string, string>;
  modelId?: string;
}

export interface ProcessingStep {
  id: string;
  index: number;
  name: string;
  creationDatetime: Date;
}

export interface Observation {
  id: string;
  metadata?: Record<string, string>;
  creationDateTime: Date;
  publisherId: string;
  images: ObservationImage[];
}

export interface ObservationImage {
  metadata?: Record<string, string>;
  url: string;
  creationDateTime: Date;
}

export enum LoadingState {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}
