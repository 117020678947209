import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { shallow } from 'zustand/shallow';

import {
  calculateTimeDiff,
  formatDate,
  statusToColors,
  statusToIcon,
} from '../../ helpers/helpers.ts';
import { ReactComponent as IconBack } from '../../assets/icon-back.svg';
import { ReactComponent as IconImageMissing } from '../../assets/icon-image-missing.svg';
import ImageModal from '../../components/image-modal/ImageModal.tsx';
import { LoadingState } from '../../models/models.ts';
import g from '../../shared.module.scss';
import { useDataStore } from '../../store/store.ts';
import sr from '../processing-jobs/processing-jobs.module.scss';
import s from './api-result-details.module.scss';
import APIResultStep from './components/api-result-step/APIResultStep.tsx';

interface Props {}

const APIResultDetails: React.FC<Props> = () => {
  const { id } = useParams();
  const [
    activeJob,
    activeObservation,
    fetchActiveJob,
    fetchActiveObservation,
    fetchObservationImageUrl,
    observationsLoadingState,
    observationImages,
  ] = useDataStore(
    (state) => [
      state.activeJob,
      state.activeObservation,
      state.fetchActiveJob,
      state.fetchActiveObservation,
      state.fetchObservationImageUrl,
      state.observationsLoadingState,
      state.observationImages,
    ],
    shallow,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (id) fetchActiveJob(id);
  }, [id]);

  useEffect(() => {
    if (activeJob) {
      fetchActiveObservation(activeJob.observationId);
    }
  }, [activeJob]);

  useEffect(() => {
    if (activeObservation && activeJob) {
      for (const image of activeObservation.images) {
        fetchObservationImageUrl(image);
      }
    }
  }, [activeObservation, activeJob]);

  const totalDuration = useMemo(() => {
    if (activeJob && activeJob.steps.length > 0) {
      return activeJob?.steps.reduce((acc, step) => {
        if (step.stopDatetime && step.startDatetime) {
          return acc + calculateTimeDiff(step.startDatetime, step.stopDatetime);
        }
        return acc + 0;
      }, 0);
    }
    return 0;
  }, [activeJob]);

  const lastStep = useMemo(() => {
    if (activeJob && activeJob.steps.length > 0) {
      return activeJob.steps.find((e) => e.status !== 'FINISHED');
    }
    return null;
  }, [activeJob]);

  const Icon = statusToIcon(activeJob?.status ?? '');

  if (observationsLoadingState === LoadingState.ERROR)
    return (
      <div className={g.pageError}>
        <span>Job not found</span>
        <button onClick={() => navigate('/results')} className={sr.table_button}>
          Go back to overview
        </button>
      </div>
    );
  const currentStepText = useMemo(() => {
    if (lastStep) {
      return lastStep.name;
    }
    if (activeJob && activeJob.steps.length > 0) {
      return 'All steps completed';
    } else {
      return '/';
    }
  }, [lastStep, activeJob]);

  if (!activeJob) return <div />;
  return (
    <div className={g.page}>
      <button className={clsx(g.pageHeader, g.button)} onClick={() => navigate('/results')}>
        <div className={g.pageHeaderNav}>
          <IconBack />
        </div>
        <h1>Details</h1>
      </button>
      <div className={g.pageBody}>
        <div className={s.details}>
          <div className={s.info}>
            <b> {formatDate(activeJob.creationDatetime)}</b>
            <span className={s.status}>
              Status: {<Icon color={statusToColors(activeJob?.status).color} />}{' '}
              <span style={{ textTransform: 'capitalize' }}>{activeJob.status.toLowerCase()}</span>
            </span>
            <span>Current step: {currentStepText}</span>
            {totalDuration > 0 && <span> Total duration: {totalDuration.toFixed(3)} s</span>}
            <span>
              Models:{' '}
              {activeJob.steps.length > 0 &&
                activeJob.steps
                  .filter((step) => step.modelId)
                  .map<React.ReactNode>((step, index) => {
                    return (
                      <a key={step.modelId} href={'#'}>
                        {index !== 0 && ', '}
                        {step.modelId}
                      </a>
                    );
                  })}
            </span>
          </div>

          {activeObservation?.images.length === 0 && (
            <div className={s.img}>
              <IconImageMissing />
            </div>
          )}

          <div className={clsx(s.img, s.img__loaded)}>
            {activeObservation?.images.map((image, i) => {
              const mapped = observationImages[image.url];
              return (
                <ImageModal key={activeObservation.id + i} imgSrc={mapped}>
                  <img id={activeObservation.id + ' ' + image.url} src={mapped} alt="" />
                </ImageModal>
              );
            })}
          </div>
        </div>

        <div className={s.stepDetails}>
          <h2>Intermediate Steps</h2>

          <div className={s.steps}>
            {activeJob.steps.map((step) => {
              return <APIResultStep step={step} key={step.name} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default APIResultDetails;
