import { GetMetricDataCommand } from '@aws-sdk/client-cloudwatch';
import EChartsReact, { EChartsOption } from 'echarts-for-react';
import React, { useEffect, useState } from 'react';

import g from '../../shared.module.scss';
import { useClientStore } from '../../store/clientStore.ts';
import { useDataStore } from '../../store/store.ts';
import s from './home.module.scss';

const processMetricData = (data): EChartsOption => {
  const result: EChartsOption = {
    xAxis: {
      type: 'time',
      axisLabel: {
        formatter: (value) => new Date(value).toLocaleTimeString(),
      },
    },
    yAxis: [
      {
        type: 'value',
        name: 'Duration (ms)',
        axisLabel: {
          fontSize: 14,
          fontFamily: 'Lato',
          letterSpacing: 1,
          formatter: (value) => `${value.toFixed(0)}`,
        },
      },
      {
        type: 'value',
        name: 'Invocations / Errors',
        max: (value) => value.max * 1.2,
        axisLabel: {
          fontSize: 14,
          fontFamily: 'Lato',
          letterSpacing: 1,
          formatter: (value) => `${value.toFixed(2)}`,
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      textStyle: {
        fontSize: 14,
        fontFamily: 'Lato',
      },
      formatter: function (params) {
        let tooltipHtml = `<b  style="color: #000000;display: block;margin-bottom: 5px;letter-spacing: 1px">${params[0].axisValueLabel}</b>`; // This is the X value
        tooltipHtml += params
          .map((param) => {
            const value = parseFloat(param.value[1]).toFixed(2);
            return `<div>
                  <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>
                  ${param.seriesName}: ${value}
                </div>`;
          })
          .join('');
        return tooltipHtml;
      },
    },
    legend: {
      textStyle: {
        fontSize: 14,
        fontFamily: 'Lato',
        letterSpacing: 1,
      },
      data: data.MetricDataResults.map((metric) => metric.Label),
    },
    series: [],
  };

  data.MetricDataResults.forEach((metric, i) => {
    const seriesData = metric.Timestamps.map((timestamp, j) => {
      return {
        name: new Date(timestamp).toISOString(),
        value: [new Date(timestamp).toISOString(), metric.Values[j]],
      };
    });

    result.series.push({
      name: metric.Label,
      type: 'line',
      smooth: true,
      showSymbol: false,
      emphasis: {
        scale: true,
      },
      yAxisIndex: i > 0 ? 1 : 0,
      data: seriesData,
    });
  });

  return result;
};

interface Props {}

const Home: React.FC<Props> = () => {
  const [cloudWatchClient] = useClientStore((state) => [state.cloudWatchClient]);
  const [envInfo] = useDataStore((state) => [state.envInfo]);
  const [chartData, setChartData] = useState<EChartsOption>();
  const [errorCount, setErrorCount] = useState(0);
  const [invocationCount, setInvocationCount] = useState(0);
  const [avgLatency, setAvgLatency] = useState(0);

  useEffect(() => {
    if (cloudWatchClient) {
      const command = new GetMetricDataCommand({
        StartTime: new Date(Date.now() - 3600000 * 24), // 24 hours
        EndTime: new Date(),
        ScanBy: 'TimestampAscending',
        MetricDataQueries: [
          {
            Id: 'q1',
            Label: 'Duration (ms)',
            MetricStat: {
              Period: 300,
              Metric: {
                Namespace: 'AWS/Lambda',
                MetricName: 'Duration',
                Dimensions: [
                  {
                    Name: 'FunctionName',
                    Value: `${envInfo.tenantId}-diagnostics-processing`,
                  },
                ],
              },
              Stat: 'Average',
            },
          },
          {
            Id: 'q2',
            Label: 'Invocations',
            MetricStat: {
              Period: 300,
              Metric: {
                Namespace: 'AWS/Lambda',
                MetricName: 'Invocations',
                Dimensions: [
                  {
                    Name: 'FunctionName',
                    Value: `${envInfo.tenantId}-diagnostics-processing`,
                  },
                ],
              },
              Stat: 'Sum',
            },
          },
          {
            Id: 'q3',
            Label: 'Errors',
            MetricStat: {
              Period: 300,
              Metric: {
                Namespace: 'AWS/Lambda',
                MetricName: 'Errors',
                Dimensions: [
                  {
                    Name: 'FunctionName',
                    Value: `${envInfo.tenantId}-diagnostics-processing`,
                  },
                ],
              },
              Stat: 'Sum',
            },
          },
        ],
      });
      cloudWatchClient.send(command).then((res) => {
        if (!res.MetricDataResults) return;
        res.MetricDataResults.forEach((result) => {
          const values = result.Values || [];
          if (result.Id === 'q1') {
            const averageValue = values.reduce((a, b) => a + b, 0) / values.length;
            setAvgLatency(averageValue);
          } else if (result.Id === 'q2') {
            const totalValue = values.reduce((a, b) => a + b, 0);
            setInvocationCount(totalValue);
          } else if (result.Id === 'q3') {
            const totalValue = values.reduce((a, b) => a + b, 0);
            setErrorCount(totalValue);
          }
        });
        setChartData(processMetricData(res));
      });
    }
  }, [cloudWatchClient]);

  return (
    <div className={g.page}>
      <div className={g.pageHeader}>
        <h1>Dashboard</h1>
      </div>
      <div className={g.pageBody}>
        <div className={s.cards}>
          <div className={s.card}>
            <h2>{invocationCount}</h2>
            <span>Requests in the last 24 hours</span>
          </div>
          <div className={s.card}>
            <h2>
              {errorCount != null && invocationCount != null && invocationCount !== 0
                ? `${((errorCount / invocationCount) * 100).toFixed(2)} %`
                : '0 %'}
            </h2>
            <span>Request Error Rate</span>
          </div>
          <div className={s.card}>
            <h2>{avgLatency ? `${(avgLatency / 1000).toFixed(2)} s` : '0 s'} </h2>
            <span>Average Response Time</span>
          </div>
        </div>
        <div className={s.graph}>
          {chartData && chartData.series && chartData.series.length > 0 && (
            <EChartsReact option={chartData} style={{ height: '100%', width: '100%' }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
