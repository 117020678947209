import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { S3ImageToURL, useDataStore } from '../../../../store/store.ts';
import s from './demo-results.module.scss';
import DemoCard from './DemoCard.tsx';

interface Props {}

const DemoResults: React.FC<Props> = () => {
  const [demoData, demoImage, setDemoImage, fetchActiveJob, activeJob, setDemoData] = useDataStore(
    (state) => [
      state.demoData,
      state.demoImage,
      state.setDemoImage,
      state.fetchActiveJob,
      state.activeJob,
      state.setDemoData,
    ],
  );
  const { id } = useParams();

  useEffect(() => {
    if (!demoData) {
      if (id) {
        fetchActiveJob(id);
      }
    }
  }, [demoData, id]);

  useEffect(() => {
    const func = async (activeJob) => {
      const step = activeJob.steps.find((e) => e.name === 'Image analysis');
      const imageStep = activeJob.steps.find((e) => e.name === 'Load image');
      if (!step || !imageStep) {
        return;
      }
      const imgUrl = await S3ImageToURL(imageStep.metadata['preprocessed image']);
      setDemoImage({ url: imgUrl, file: imgUrl });
      setDemoData(step.metadata);
    };
    if (!demoData && activeJob) {
      func(activeJob);
    }
  }, [demoData, activeJob]);

  const navigate = useNavigate();
  if (!demoData || !demoImage) {
    return null;
  }
  return (
    <div className={s.container}>
      <div className={s.top}>
        <div className={s.background}>
          <img className={s.backgroundImage} src={demoImage?.url} alt="" />
        </div>

        <h2>Analysis Result</h2>
        <div className={s.inner}>
          <div className={s.img}>
            <img src={demoImage?.url} alt="" />
          </div>
          <p>{demoData.response.summary}</p>
        </div>
        <div className={s.actions}>
          <button
            onClick={() => {
              setDemoImage(null);
              navigate('/');
            }}
          >
            Start Over
          </button>
        </div>
      </div>
      <div className={s.bottom}>
        <h3>Possible Defects</h3>
        <span>
          These possible defects were found based on the provided image. <br />
          Each defect has an indicator te indicate the likelihood that the defect is present in the
          image you've uploaded.
        </span>
        <div className={s.cards}>
          {(demoData.response.defects || [])
            .sort((a, b) => (a.score < b.score ? 1 : -1))
            .map((data) => {
              return <DemoCard key={data.name} data={data} />;
            })}
        </div>
        <span className={s.notice}>
          Want to get in touch with us?{' '}
          <a target="_blank" href="https://www.ml6.eu/contact" rel="noreferrer">
            Contact Us
          </a>
        </span>
      </div>
    </div>
  );
};

export default DemoResults;
