import clsx from 'clsx';
import React, { cloneElement, useState } from 'react';

import { ReactComponent as IconCross } from '../../assets/icon-cross.svg';
import s from './image-modal.module.scss';

interface Props {
  imgSrc: string;
  children: any;
}

const ImageModal: React.FC<Props> = ({ imgSrc, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = (e) => {
    if (e.currentTarget === e.target) setIsModalOpen(false);
  };
  return (
    <>
      {cloneElement(children, {
        style: { cursor: 'pointer' },
        onClick: () => setIsModalOpen(true),
      })}
      <div
        onClick={handleClose}
        className={clsx(s.container, { [s.container__open]: isModalOpen })}
      >
        <div className={s.imageWrapper}>
          <img src={imgSrc} alt="Modal Content" />
          <button className={s.close} onClick={() => setIsModalOpen(false)}>
            <IconCross />
          </button>
        </div>
      </div>
    </>
  );
};

export default ImageModal;
