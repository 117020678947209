import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import Toggle from '../../components/toggle/Toggle.tsx';
import s from './ai-models.module.scss';

interface Props {
  matrix: any;
  classIdToName: any;
}
enum ConfusionMatrixState {
  NORMALIZED,
  UNNORMALIZED,
}

const ConfusionMatrix: React.FC<Props> = ({ matrix, classIdToName }) => {
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<number | null>(null);
  const [confusionMatrixState, setConfusionMatrixState] = useState<any>(
    ConfusionMatrixState.NORMALIZED,
  );

  const findMaxValue = (matrix) => {
    return Math.max(...matrix.flat());
  };

  const getColor = (value, maxValue) => {
    if (value == 0) return '';
    const intensity = value / maxValue;
    const lightness = 100 - intensity * 35;
    return `hsl(40, 100%, ${lightness}%)`;
  };
  if (Object.entries(matrix).length === 0) return null;
  const selectedMatrix =
    confusionMatrixState === ConfusionMatrixState.NORMALIZED
      ? matrix.matrix_normalized
      : matrix.matrix;

  const maxValue = findMaxValue(selectedMatrix);
  useEffect(() => {
    console.log(confusionMatrixState);
  }, [confusionMatrixState]);
  return (
    <div className={s.confusion_matrix_wrapper}>
      <div className={s.confusionMatrixContainer}>
        <table
          onMouseLeave={() => {
            setHoveredRow(null);
            setHoveredColumn(null);
          }}
          className={s.confusionMatrix}
        >
          <span className={clsx(s.axisLabel, s.x)}>Actual</span>
          <span className={clsx(s.axisLabel, s.y)}>Predicted</span>
          <thead>
            <tr>
              <th
                onMouseOver={() => {
                  setHoveredRow(null);
                  setHoveredColumn(null);
                }}
              ></th>
              {Object.entries(classIdToName).map(([id, name], index) => (
                <th
                  key={id}
                  className={clsx(s.verticalHeader, {
                    [s.highlight]: hoveredColumn === index,
                  })}
                  onMouseOver={() => {
                    setHoveredColumn(index);
                    setHoveredRow(null);
                  }}
                >
                  <div>
                    <span>{name as string}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {selectedMatrix.map((row, rowIndex) => (
              <tr key={rowIndex} onMouseOver={() => setHoveredRow(rowIndex)}>
                <th className={clsx({ [s.highlight]: hoveredRow === rowIndex })}>
                  {classIdToName[rowIndex]}
                </th>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className={clsx(s.cellValue, {
                      [s.highlight]: hoveredRow === rowIndex || hoveredColumn === cellIndex,
                    })}
                    onMouseOver={() => {
                      setHoveredRow(rowIndex);
                      setHoveredColumn(cellIndex);
                    }}
                    style={{
                      backgroundColor: getColor(cell, maxValue),
                      color: cell === 0 ? 'transparent' : 'black',
                    }}
                  >
                    {confusionMatrixState === ConfusionMatrixState.NORMALIZED
                      ? cell.toFixed(2)
                      : cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={s.buttons}>
        <Toggle
          currentPosition={confusionMatrixState}
          positions={[
            { name: 'Normalized', key: 0 },
            { name: 'Absolute', key: 1 },
          ]}
          handleChange={(newPos) => setConfusionMatrixState(newPos)}
        />
      </div>
    </div>
  );
};

export default ConfusionMatrix;
