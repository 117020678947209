import { GetObjectCommand } from '@aws-sdk/client-s3';
import { QueryCommand } from '@aws-sdk/lib-dynamodb';
import { API, Auth } from 'aws-amplify';
import camelcaseKeys from 'camelcase-keys';
import { create } from 'zustand';

import { convertLineage, parseModel } from '../ helpers/helpers.ts';
import {
  LoadingState,
  Model,
  Observation,
  ObservationImage,
  ProcessingJob,
  StatusEnum,
} from '../models/models.ts';
import { GET_PROCESSING_JOB, LIST_PROCESSING_JOBS } from '../queries/queries.ts';
import { TagFilterValue } from '../routes/processing-jobs/components/tag-filter/TagFilter.tsx';
import { useClientStore } from './clientStore.ts';

type AuthState = {
  authenticatedUser?: any;
  setAuthenticatedUser: (user: any) => void;
};

export const useAuthStore = create<AuthState>((set) => ({
  authenticatedUser: undefined,
  setAuthenticatedUser: (user: any) => set({ authenticatedUser: user }),
}));

type DataState = {
  envInfo: { tenantId: string; env: string };
  models: Model[];
  model: Model | null;
  modelsLoadingState: LoadingState;
  modelLineage: any;
  processingJobs: ProcessingJob[];
  activeJob?: ProcessingJob | null;
  tagFilterOptions?: string[];
  observations: Observation[];
  activeObservation?: Observation | null;
  observationsLoadingState: LoadingState;
  observationLoadingIds: Record<string, boolean>;
  observationImages: { [id: string]: string };
  setObservationImages: (images: { [id: string]: string }) => void;
  fetchModels: () => void;
  fetchModel: (id: string) => void;
  fetchModelLineage: (id: string) => void;
  setModelLineage: (data: any) => void;
  activeStatusFilter: StatusEnum | undefined;
  activeTagFilter: TagFilterValue[] | undefined;
  activeSortingDirection: 'DESC' | 'ASC';
  activeDateRange: [Date, Date] | undefined;
  setActiveStatusFilter;
  setActiveTagFilter;
  setActiveSortingDirection;
  setActiveDateRange;
  fetchProcessingJobs: (
    sortDirection: string,
    status?: StatusEnum,
    tags?: TagFilterValue[],
    dateRange?: [Date, Date],
  ) => void;
  fetchActiveJob: (id: string) => void;
  fetchObservationById: (id: string) => void;
  fetchActiveObservation: (id: string) => void;
  fetchObservationImageUrl: (image: ObservationImage) => void;
  setEnvInfo: (envInfo: { tenantId: string; env: string }) => void;
  demoData: any;
  setDemoData: (data: any) => void;
  setDemoImage: (data: any) => void;
  demoImage: { file: File; url: string } | null;
};

export const S3ImageToURL = async (url: string) => {
  const mainS3Client = useClientStore.getState().mainS3Client;

  if (!mainS3Client) return;

  const cleaned = url.replace('s3://', '');
  const index = cleaned.indexOf('/');
  const bucket = cleaned.substring(0, index);
  const filePath = cleaned.substring(index + 1);
  const command = new GetObjectCommand({ Bucket: bucket, Key: filePath });

  try {
    const res = await mainS3Client.send(command);
    const byteArray = await res.Body?.transformToByteArray();
    if (!byteArray) throw new Error('No response body');
    const blob = new Blob([new Uint8Array(byteArray)], { type: res.ContentType });
    const newImageUrl = URL.createObjectURL(blob);
    return newImageUrl;
  } catch (err) {
    console.error('Failed to fetch image:', err);
  }
};
const today = new Date();
today.setHours(23, 59, 59, 999);

export const useDataStore = create<DataState>((set, get) => ({
  envInfo: {
    tenantId: '',
    env: '',
  },
  isDataLoading: false,
  processingJobs: [],
  models: [],
  modelsLoadingState: LoadingState.IDLE,
  model: null,
  modelLineage: null,
  setModelLineage: (data) => set({ modelLineage: data }),
  tagFilterOptions: [],
  activeStatusFilter: undefined,
  activeTagFilter: undefined,
  activeSortingDirection: 'DESC',
  activeDateRange: [new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7), today],
  setActiveStatusFilter: (status) => set({ activeStatusFilter: status }),
  setActiveTagFilter: (tag) => set({ activeTagFilter: tag }),
  setActiveSortingDirection: (direction) => set({ activeSortingDirection: direction }),
  setActiveDateRange: (dateRange) => set({ activeDateRange: dateRange }),
  activeJob: null,
  activeObservation: null,
  observations: [],
  observationImages: {},
  demoData: null,
  demoImage: null,
  setDemoData: (data) => set({ demoData: data }),
  setDemoImage: (data) => set({ demoImage: data }),
  setEnvInfo: (envInfo) => set({ envInfo }),
  observationsLoadingState: LoadingState.IDLE,
  observationLoadingIds: {},
  fetchObservationImageUrl: async (image) => {
    let currentImages = get().observationImages;
    if (currentImages[image.url]) return;
    const newImageUrl = await S3ImageToURL(image.url);
    currentImages = get().observationImages;
    if (newImageUrl) {
      set({ observationImages: { ...currentImages, [image.url]: newImageUrl } });
    }
  },

  setObservationImages: (images) => set({ observationImages: images }),
  fetchModels: async () => {
    set({ modelsLoadingState: LoadingState.LOADING });
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    const envInfo = get().envInfo;
    let apiUrl = `https://api.${envInfo.env}.diagnostics.ml6.eu/v1/models`;
    if (envInfo.env === 'prd') apiUrl = `https://api.diagnostics.ml6.eu/v1/models`;

    const data = await fetch(apiUrl, { headers: { Authorization: token } })
      .then((res) => res.json())
      .then((output) => {
        const models: Model[] = output.map((model: any) => parseModel(model)) ?? [];

        return models;
      })
      .catch(() => {
        set({ modelsLoadingState: LoadingState.ERROR });
        return [];
      });

    set({ models: data, modelsLoadingState: LoadingState.SUCCESS });
  },
  fetchModel: async (base64Id: string) => {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    const id = encodeURIComponent(atob(base64Id));
    const envInfo = get().envInfo;
    let apiUrl = `https://api.${envInfo.env}.diagnostics.ml6.eu/v1/models/${id}`;
    if (envInfo.env === 'prd') apiUrl = `https://api.diagnostics.ml6.eu/v1/models/${id}`;
    await fetch(apiUrl, { headers: { Authorization: token } })
      .then((res) => res.json())
      .then((model) => {
        const data = parseModel(model);
        set({ model: data });
      });
  },
  fetchModelLineage: async (base64Id: string) => {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    const id = encodeURIComponent(atob(base64Id));
    const envInfo = get().envInfo;
    let apiUrl = `https://api.${envInfo.env}.diagnostics.ml6.eu/v1/models/${id}/lineage`;
    if (envInfo.env === 'prd') apiUrl = `https://api.diagnostics.ml6.eu/v1/models/${id}/lineage`;

    await fetch(apiUrl, { headers: { Authorization: token } })
      .then((res) => res.json())
      .then((output) => {
        const converted = convertLineage(output);
        set({ modelLineage: converted });
      });
  },
  fetchProcessingJobs: async (
    sortDirection: string,
    status?: StatusEnum,
    tags?: TagFilterValue[],
    dateRange?: [Date, Date],
  ) => {
    set({ observationsLoadingState: LoadingState.LOADING, observationLoadingIds: {} });

    const params: any = { sortDirection };

    if (status) {
      params.status = StatusEnum[status];
    }

    if (tags && tags[0]) {
      const currentTag = tags[0];
      params.tagValue = currentTag.value;
      params.tagKey = currentTag.key;
    }

    if (dateRange) {
      // set startdate to 00:00:00 and enddate to 23:59:59
      dateRange[0].setHours(0, 0, 0, 0);
      dateRange[1].setHours(23, 59, 59, 999);
      params.startDate = dateRange[0].toISOString();
      params.endDate = dateRange[1].toISOString();
    }
    try {
      const res = await API.graphql({ query: LIST_PROCESSING_JOBS, variables: params });
      const mapped: ProcessingJob[] = res['data'].listProcessingJobs.map((i) =>
        parseProcessingJob(i),
      );
      mapped.sort((a, b) => {
        if (sortDirection === 'DESC') {
          return new Date(b.creationDatetime).getTime() - new Date(a.creationDatetime).getTime();
        }
        return new Date(a.creationDatetime).getTime() - new Date(b.creationDatetime).getTime();
      });
      const uniqueTags: string[] = mapped.reduce((a: any[], b) => {
        if (!b.tags) return a;
        return [...new Set([...a, ...Object.keys(b.tags)])];
      }, []);
      set({
        processingJobs: mapped ?? [],
        tagFilterOptions: uniqueTags,
        observationsLoadingState: LoadingState.SUCCESS,
      });
    } catch (error) {
      // Handle the error here
      console.error('Error fetching processing jobs:', error);
      set({ observationsLoadingState: LoadingState.ERROR });
    }
  },
  fetchActiveJob: async (id: string) => {
    const processingJobs = get().processingJobs;
    set({ observationsLoadingState: LoadingState.LOADING });

    if (processingJobs.length === 0) {
      const params = { PK: `PROCESSING_JOB#${id}` };
      const req = await API.graphql({ query: GET_PROCESSING_JOB, variables: params });
      if (!req['data'].getProcessingJob) {
        set({ observationsLoadingState: LoadingState.ERROR });
        return;
      }
      set({
        activeJob: parseProcessingJob(req['data'].getProcessingJob),
        observationsLoadingState: LoadingState.SUCCESS,
      });
    } else {
      const job = processingJobs.find((i) => i.id === id);
      if (job) {
        set({ activeJob: job, observationsLoadingState: LoadingState.SUCCESS });
      }
    }
  },
  fetchActiveObservation: (id: string) => {
    set({ observationsLoadingState: LoadingState.LOADING });
    const { tenantId } = get().envInfo;

    const observations = get().observations;
    if (observations.length === 0) {
      const dynamoDbClient = useClientStore.getState().dynamoDbClient;
      if (dynamoDbClient) {
        const command = new QueryCommand({
          TableName: `${tenantId}-Observations`,
          KeyConditionExpression: 'PK = :pkValue',
          ExpressionAttributeValues: {
            ':pkValue': 'OBSERVATION#' + id,
          },
        });

        dynamoDbClient.send(command).then((d) => {
          if (!d.Items) return;
          const mappedObservations = parseObservations(d.Items);
          set({
            activeObservation: mappedObservations[0],
            observationsLoadingState: LoadingState.SUCCESS,
          });
        });
      }
    } else {
      const observation = observations.find((i) => i.id === id);
      if (observation) {
        set({ activeObservation: observation, observationsLoadingState: LoadingState.SUCCESS });
      }
    }
  },
  fetchObservationById: (id: string) => {
    const currentLoadingIds = get().observationLoadingIds;
    const observations = get().observations;

    if (currentLoadingIds[id] || observations.find((e) => e.id === id)) return;
    set({ observationLoadingIds: { ...currentLoadingIds, [id]: true } });
    const { tenantId } = get().envInfo;

    const dynamoDbClient = useClientStore.getState().dynamoDbClient;
    if (dynamoDbClient) {
      const command = new QueryCommand({
        TableName: `${tenantId}-Observations`,
        KeyConditionExpression: 'PK = :pkValue',
        ExpressionAttributeValues: {
          ':pkValue': 'OBSERVATION#' + id,
        },
      });

      dynamoDbClient.send(command).then((d) => {
        if (!d.Items || d.Items.length === 0) return;
        const mappedObservations = parseObservations(d.Items);
        const newObservation = mappedObservations[0];
        let currentObservations = [...get().observations];
        if (currentObservations.find((e) => e.id === id)) {
          currentObservations = currentObservations.map((i) => {
            if (i.id === id) {
              return newObservation;
            }
            return i;
          });
        } else {
          currentObservations = [...currentObservations, newObservation];
        }

        set({
          observations: currentObservations,
          observationsLoadingState: LoadingState.SUCCESS,
          observationLoadingIds: { ...currentLoadingIds, [id]: false },
        });
      });
    }
  },
}));

const parseObservations = (data: Record<string, any>[]) => {
  const observationsById: Record<string, Observation> = {};

  const mappedObservations = data
    .sort((a, b) => {
      return (
        new Date(b['creation_datetime']).getTime() - new Date(a['creation_datetime']).getTime()
      );
    })
    .filter((i) => i.SK === 'INFO')
    .map((item) => {
      const observation: Observation = {
        id: item.PK.replace('OBSERVATION#', ''),
        creationDateTime: new Date(item['creation_datetime']),
        metadata: item.metadata,
        publisherId: item.publisherId,
        images: [],
      };
      observationsById[observation.id] = observation;
      return observation;
    });

  data
    .filter((i) => i.SK !== 'INFO')
    .sort((a, b) => {
      return (
        new Date(a['creation_datetime']).getTime() - new Date(b['creation_datetime']).getTime()
      );
    })
    .forEach((item) => {
      const mappedItem: ObservationImage = {
        creationDateTime: new Date(item['creation_datetime']),
        metadata: item.metadata,
        url: item.url,
      };
      const relatedObservation = observationsById[item.PK.replace('OBSERVATION#', '')];
      if (relatedObservation) relatedObservation.images.push(mappedItem);
    });

  return mappedObservations as Observation[];
};

const parseProcessingJob = (data: any): ProcessingJob => {
  let mapped = camelcaseKeys(data, { deep: true });
  mapped = {
    ...mapped,
    id: mapped.pk.replace('PROCESSING_JOB#', ''),
    creationDatetime: new Date(mapped.creationDatetime),
    startDatetime: new Date(mapped.startDatetime),
    stopDatetime: new Date(mapped.stopDatetime),
    tags: mapped.tags ? JSON.parse(mapped.tags) : [],
    steps: mapped.steps
      .map((i) => {
        return {
          ...i,
          creationDatetime: new Date(i.creationDatetime),
          startDatetime: new Date(i.startDatetime),
          stopDatetime: new Date(i.stopDatetime),
          description: i.description,
          metadata: JSON.parse(i.metadata),
          tags: i.tags ? JSON.parse(i.tags) : [],
          modelId: i.modelId,
        };
      })
      .sort((a, b) => a.index - b.index),
  } as ProcessingJob;
  return mapped;
};
