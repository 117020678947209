import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import React from 'react';
import { useLocation } from 'react-router';
import { Outlet, useNavigate } from 'react-router-dom';

import { ReactComponent as IconAI } from '../../assets/icon-ai.svg';
import { ReactComponent as IconExternal } from '../../assets/icon-external.svg';
import { ReactComponent as IconHome } from '../../assets/icon-home.svg';
import { ReactComponent as IconLabeling } from '../../assets/icon-labeling.svg';
import { ReactComponent as IconResults } from '../../assets/icon-results.svg';
import { ReactComponent as IconSignOut } from '../../assets/icon-sign-out.svg';
import { ReactComponent as LogoMicrotechnix } from '../../assets/logo-microtechnix.svg';
import { ReactComponent as LogoML6 } from '../../assets/logo-ml6.svg';
import { useDataStore } from '../../store/store.ts';
import s from './layout.module.scss';

interface Props {}

const Layout: React.FC<Props> = () => {
  const logOut = () => {
    Auth.signOut().then(() => {
      navigate('/login');
    });
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [envInfo] = useDataStore((state) => [state.envInfo]);
  return (
    <div className={s.container}>
      <aside className={s.sidebar}>
        <div className={s.header}>
          {envInfo.tenantId === 'microtechnix' && (
            <>
              <div className={s.header_item}>
                <LogoMicrotechnix />
              </div>
              <div className={clsx(s.header_item, s.header_item__center)}>
                <span>Powered by</span>
              </div>
            </>
          )}
          <div className={s.header_item}>
            <LogoML6 />
            <span>Vision AI</span>
          </div>
        </div>

        <nav>
          <button
            className={clsx(s.navRow, {
              [s.navRow__active]: location.pathname === '/',
            })}
            onClick={() => navigate('/')}
          >
            <IconHome /> <span>Home</span>
          </button>
          <button
            className={clsx(s.navRow, {
              [s.navRow__active]: location.pathname.includes('/results'),
            })}
            onClick={() => navigate('/results')}
          >
            <IconResults /> <span>API Results</span>
          </button>

          <button
            className={clsx(s.navRow, {
              [s.navRow__active]: location.pathname.includes('/ai-models'),
            })}
            onClick={() => navigate('/ai-models')}
          >
            <IconAI /> <span>AI Models</span>
          </button>
          <button
            className={clsx(s.navRow, {
              [s.navRow__active]: location.pathname === '/upload',
            })}
            onClick={() => navigate('/upload')}
          >
            <IconAI /> <span>Upload</span>
          </button>
          <button
            className={clsx(s.navRow, {
              [s.navRow__active]: location.pathname === '/labeling',
            })}
            onClick={() =>
              window.open(
                envInfo.env === 'prd'
                  ? `https://${envInfo.tenantId}.label.diagnostics.ml6.eu`
                  : `https://${envInfo.tenantId}.label.${envInfo.env}.diagnostics.ml6.eu`,
                '_blank',
              )
            }
          >
            <IconLabeling />
            <span>Data Labeling</span>
            <IconExternal
              style={{
                width: 'auto',
                marginTop: -1,
                marginLeft: 'auto',
                marginRight: 0,
                height: 14,
              }}
            />
          </button>
        </nav>
        <div className={s.sidebarBottom}>
          <button onClick={() => logOut()}>
            <span>Sign Out</span>
            <IconSignOut />
          </button>
        </div>
      </aside>
      <main className={s.body}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
