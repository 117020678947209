import { Ring } from '@uiball/loaders';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import React, { useMemo, useRef, useState } from 'react';

import { sleep } from '../../ helpers/helpers.ts';
import { ReactComponent as IconChevronDown } from '../../assets/icon-chevron-down.svg';
import g from '../../shared.module.scss';
import { useDataStore } from '../../store/store.ts';
import s from './upload.module.scss';

const Form: React.FC = () => {
  const [observationMetadata, setObservationMetadata] = useState<string | null>(null);
  const [envInfo] = useDataStore((state) => [state.envInfo]);
  const [image, setImage] = useState<{ file: File | null; metadata: string | null }>({
    file: null,
    metadata: null,
  });
  const [status, setStatus] = useState('pending');
  const [imageURL, setImageURL] = useState('');
  const [isAdvancedVisible, setIsAdvancedVisible] = useState(false);
  const handleImageChange = (file: File | null, metadata: string | null) => {
    let newImage = { ...image };
    if (file) newImage = { ...newImage, file: file };
    if (metadata) newImage = { ...newImage, metadata: metadata };
    setImage(newImage);
  };

  const resetForm = () => {
    setObservationMetadata(null);
    setImage({ file: null, metadata: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      console.error('One or more files are not selected');
      return;
    }
    setStatus('uploading');

    const formData = new FormData();
    formData.append('invocationMode', 'POLLING');
    formData.append('observation', JSON.stringify({ publisherId: 'frontend' }));
    if (image.file) {
      formData.append(`images`, image.file);
    } else if (imageURL && imageURL !== '') {
      formData.append('images', JSON.stringify([imageURL]));
    }

    let apiUrl = `https://api.${envInfo.env}.diagnostics.ml6.eu/v1/jobs`;
    if (envInfo.env === 'prd') apiUrl = `https://api.diagnostics.ml6.eu/v1/jobs`;
    const url = new URL(apiUrl);
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        Authorization: token,
      },
      body: formData,
    });
    if (response.status === 201) {
      setStatus('success');
      sleep(3000).then(() => {
        setStatus('pending');
        resetForm();
      });
    } else {
      setStatus('error');
    }
  };
  const imageRef = useRef(null);

  const onClick = () => {
    if (imageRef.current) {
      const buttonElement = imageRef.current as HTMLButtonElement;
      buttonElement.click();
    }
  };
  const buttonText = useMemo(() => {
    if (image.file) {
      return 'Change image';
    } else {
      return 'Select an image';
    }
  }, [image]);

  return (
    <div className={g.page}>
      <div className={g.pageHeader}>
        <h1>Upload</h1>
      </div>
      <div className={g.pageBody}>
        <form className={s.formContainer} onSubmit={handleSubmit}>
          <h2>Upload</h2>

          <button disabled={!!imageURL} type={'button'} onClick={onClick}>
            {buttonText}
          </button>
          <input
            ref={imageRef}
            style={{ display: 'none' }}
            type="file"
            onChange={(e) => handleImageChange(e.target.files ? e.target.files[0] : null, null)}
          />
          <span>or</span>
          <input
            disabled={!!image.file}
            value={imageURL}
            onChange={(e) => setImageURL(e.target.value)}
            className={s.imageInput}
            type="url"
            placeholder={'Enter image URL'}
          />
          <div className={clsx(s.advanced, { [s.advanced__visible]: isAdvancedVisible })}>
            <div onClick={() => setIsAdvancedVisible(!isAdvancedVisible)} className={s.header}>
              Advanced Options
              <IconChevronDown />
            </div>
            <div className={s.content}>
              <label htmlFor="image-json-input">Image Metadata</label>
              <textarea
                id={'image-json-input'}
                value={image?.metadata ?? ''}
                style={{ resize: 'none', minHeight: 100 }}
                className={s.imageInput}
                placeholder={'Enter image metadata (JSON Format)'}
                onChange={(e) => handleImageChange(null, e.target.value)}
              />
              <label htmlFor="json-input">Metadata</label>
              <textarea
                value={observationMetadata ?? ''}
                style={{ resize: 'none', minHeight: 100 }}
                id="json-input"
                className={s.jsonInput}
                placeholder={'Enter general metadata (JSON Format)'}
                onChange={(e) => setObservationMetadata(e.target.value)}
              />
            </div>
          </div>
          <button disabled={image?.file == null && !imageURL} type="submit">
            {status === 'pending' && 'Submit'}
            {status === 'uploading' && <Ring size={20} color={'white'} />}
            {status === 'error' && 'Something went wrong'}
            {status === 'success' && 'Submission Successful'}
          </button>
        </form>
      </div>
    </div>
  );
};
export default Form;
