import React, { useEffect, useState } from 'react';

import s from './progress-bar.module.scss';

interface Props {}

const ProgressBar: React.FC<Props> = () => {
  const [progress, setProgress] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        // Slow down as it approaches 100
        const newProgress = prevProgress + (100 - prevProgress) * 0.01;
        // Prevent it from actually reaching 100
        return newProgress >= 99 ? 99 : newProgress;
      });
    }, 100); // Update every 100 milliseconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={s.container}>
      <div className={s.progress} style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
