import React from 'react';

import s from './ai-models.module.scss';

interface Props {
  stats: any;
  classIdToName: any;
}

const ModelStatistics: React.FC<Props> = ({ stats: { mean, per_class }, classIdToName }) => {
  const classIds = Object.keys(per_class.accuracy);

  return (
    <div className={s.statistics}>
      <h2>Per-Class Statistics</h2>
      <table>
        <thead>
          <tr>
            <th>Class</th>
            {Object.keys(per_class).map((metric) => (
              <th key={metric}>{metric}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {classIds.map((classId) => (
            <tr key={classId}>
              <td>{classIdToName[classId]}</td>
              {Object.keys(per_class).map((metric) => (
                <td key={metric}>
                  {per_class[metric][classId] !== null
                    ? per_class[metric][classId].toFixed(3)
                    : 'N/A'}
                </td>
              ))}
            </tr>
          ))}
          <tr style={{ background: 'rgba(0, 0, 0, 0.05)' }}>
            <td>Mean Values</td>
            {Object.entries(mean).map(([_, value]: any) => (
              <>
                {/*<td>{metric}</td>*/}
                <td>{value !== null ? value.toFixed(3) : 'N/A'}</td>
              </>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ModelStatistics;
