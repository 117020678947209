import { Ring } from '@uiball/loaders';
import { useEffect } from 'react';
import { DateRangePicker } from 'rsuite';

import { ReactComponent as IconChevronDown } from '../../assets/icon-chevron-down.svg';
import Toggle from '../../components/toggle/Toggle.tsx';
import { LoadingState, StatusEnum } from '../../models/models.ts';
import g from '../../shared.module.scss';
import { useDataStore } from '../../store/store.ts';
import JobRow from './components/job-row/JobRow.tsx';
import TagFilter, { TagFilterValue } from './components/tag-filter/TagFilter.tsx';
import s from './processing-jobs.module.scss';

const ProcessingJobs = () => {
  const [
    processingJobs,
    fetchJobs,
    observationsLoadingState,
    setActiveTagFilter,
    setActiveDateRange,
    setActiveSortingDirection,
    setActiveStatusFilter,
    activeTagFilter,
    activeDateRange,
    activeSortingDirection,
    activeStatusFilter,
  ] = useDataStore((state) => [
    state.processingJobs,
    state.fetchProcessingJobs,
    state.observationsLoadingState,
    state.setActiveTagFilter,
    state.setActiveDateRange,
    state.setActiveSortingDirection,
    state.setActiveStatusFilter,
    state.activeTagFilter,
    state.activeDateRange,
    state.activeSortingDirection,
    state.activeStatusFilter,
  ]);

  const handleFilterStatus = (status?: StatusEnum) => {
    setActiveStatusFilter(status);
  };
  const handleTagFilter = (filters?: TagFilterValue[]) => {
    setActiveTagFilter(filters);
  };
  const handleSorting = () => {
    setActiveSortingDirection(activeSortingDirection === 'DESC' ? 'ASC' : 'DESC');
  };
  const fetchJobsWithFiltering = () => {
    fetchJobs(
      activeSortingDirection,
      activeStatusFilter,
      activeTagFilter,
      activeDateRange ?? undefined,
    );
  };

  useEffect(() => {
    fetchJobsWithFiltering();
  }, [activeSortingDirection, activeStatusFilter, activeTagFilter, activeDateRange]);

  return (
    <div className={g.page}>
      <div className={g.pageHeader}>
        <h1>API Results</h1>
      </div>
      <div className={g.pageBody}>
        <div className={s.filter}>
          <div style={{ marginRight: 'auto' }}>
            <Toggle
              currentPosition={activeStatusFilter}
              positions={[
                { key: undefined, name: 'All' },
                { key: StatusEnum.RUNNING, name: 'Running' },
                { key: StatusEnum.FINISHED, name: 'Finished' },
                { key: StatusEnum.FAILED, name: 'Failed' },
              ]}
              handleChange={handleFilterStatus}
            />
          </div>
          <div className={s.datePicker}>
            <DateRangePicker
              placeholder="Filter Date Range"
              placement={'auto'}
              value={activeDateRange}
              onChange={(e) => {
                if (!e) {
                  setActiveDateRange(undefined);
                } else {
                  setActiveDateRange(e);
                }
              }}
            />
          </div>
          <TagFilter handleFilter={handleTagFilter} />

          <button
            className={s.button}
            style={{ width: 120 }}
            onClick={() => {
              fetchJobsWithFiltering();
            }}
          >
            {observationsLoadingState === LoadingState.LOADING ? (
              <Ring size={18} color={'#FF913AFF'} />
            ) : (
              'Reload Data'
            )}
          </button>
        </div>
        {observationsLoadingState === LoadingState.LOADING && (
          <div className={s.loading_wrapper}>
            <Ring color={'#FF913AFF'} />
          </div>
        )}
        {observationsLoadingState === LoadingState.ERROR && (
          <div className={s.loading_wrapper}>
            Something went wrong, reload the page and try again.
          </div>
        )}
        {observationsLoadingState === LoadingState.SUCCESS && (
          <div className={s.table}>
            <div className={s.tableHeader}>
              <div className={s.column} />
              <button className={s.column} onClick={handleSorting}>
                <span>Date</span>
                <IconChevronDown
                  style={{
                    transform:
                      activeSortingDirection === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </button>
              <div className={s.column}>Status</div>
              <div className={s.column}>Steps</div>
              <div className={s.column}>Duration</div>
            </div>
            {processingJobs.length === 0 && <div className={s.noResults}>No results Found</div>}
            {processingJobs &&
              processingJobs.length > 0 &&
              processingJobs.map((row) => <JobRow key={row.id} job={row} />)}
          </div>
        )}
        {processingJobs.length > 197 && activeStatusFilter === undefined && (
          <p className={s.maxResults}>Results limit reached. Try adjusting filters.</p>
        )}
      </div>
    </div>
  );
};

export default ProcessingJobs;
