import { Authenticator } from '@aws-amplify/ui-react';
import { Waveform } from '@uiball/loaders';
import { Amplify } from 'aws-amplify';
import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import { ReactComponent as Animation } from './assets/animation.svg';
import { ReactComponent as LogoML6 } from './assets/logo-ml6.svg';
import awsConfig from './aws/aws-exports.js';
import Layout from './components/layout/Layout.tsx';
import RequireAuth from './components/require-auth/RequireAuth.tsx';
import AIModelDetails from './routes/ai-models/AIModelDetails.tsx';
import AIModels from './routes/ai-models/AIModels.tsx';
import APIResultDetails from './routes/api-result-details/APIResultDetails.tsx';
import DemoResults from './routes/demo/components/demo-results/DemoResults.tsx';
import DemoUpload from './routes/demo/components/demo-upload/DemoUpload.tsx';
import Home from './routes/home/Home.tsx';
import s from './routes/login/login.module.scss';
import Login from './routes/login/Login.tsx';
import ProcessingJobs from './routes/processing-jobs/ProcessingJobs.tsx';
import Upload from './routes/upload/Upload.tsx';
import { useDataStore } from './store/store.ts';

function MyRoutes({ tenant }: { tenant: string }) {
  if (tenant === 'mmv-demo') {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <DemoUpload />
              </RequireAuth>
            }
          />
          <Route
            path="/results/:id"
            element={
              <RequireAuth>
                <DemoResults />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />

          <Route
            index
            path={'/results'}
            element={
              <RequireAuth>
                <ProcessingJobs />
              </RequireAuth>
            }
          />
          <Route
            index
            path={'/results/:id'}
            element={
              <RequireAuth>
                <APIResultDetails />
              </RequireAuth>
            }
          />
          <Route
            index
            path={'/ai-models'}
            element={
              <RequireAuth>
                <AIModels />
              </RequireAuth>
            }
          />
          <Route
            index
            path={'/ai-models/:id'}
            element={
              <RequireAuth>
                <ReactFlowProvider>
                  <AIModelDetails />
                </ReactFlowProvider>
              </RequireAuth>
            }
          />
          <Route
            index
            path={'/upload'}
            element={
              <RequireAuth>
                <Upload />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const envArr = ['dev', 'tst', 'pentest', 'prd'];
function App() {
  const [isConfigured, setIsConfigured] = useState(false);
  const [setEnvInfo, envInfo] = useDataStore((state) => [state.setEnvInfo, state.envInfo]);
  const initAWS = async () => {
    const url = new URL(window.location.href);
    let [tenant, env] = url.hostname.split('.');
    if (url.hostname === 'localhost') {
      tenant = 'ml6';
      env = 'dev';
    } else if (url.hostname === 'vision-demo.ml6.eu') {
      tenant = 'mmv-demo';
      env = 'prd';
    } else {
      env = envArr.includes(env) ? env : envArr.includes(tenant) ? tenant : 'prd';
      tenant = tenant === env ? 'ml6' : tenant || 'ml6';
    }
    setEnvInfo({ tenantId: tenant, env: env });

    let domainUrl = `${tenant}-${env}-cv-diagnostics.auth.eu-west-3.amazoncognito.com`;
    if (env === 'prd') domainUrl = `${tenant}-cv-diagnostics.auth.eu-west-3.amazoncognito.com`;
    let apiUrl = `https://api.${env}.diagnostics.ml6.eu/v1/tenants/${tenant}`;
    if (env === 'prd') apiUrl = `https://api.diagnostics.ml6.eu/v1/tenants/${tenant}`;
    const data = await fetch(apiUrl).then((res) => res.json());

    const redirect = window.location.origin;

    const config = {
      ...awsConfig,
      aws_appsync_graphqlEndpoint: data['graphqlEndpoint'],
      Auth: {
        ...awsConfig.Auth,
        userPoolId: data['userPoolId'],
        userPoolWebClientId: data['userPoolClientId'],
        identityPoolId: data['identityPoolId'],
      },
      oauth: {
        ...awsConfig.oauth,
        redirectSignIn: redirect,
        redirectSignOut: redirect,
        domain: domainUrl,
      },
    };

    Amplify.configure(config);
    setIsConfigured(true);
  };

  useEffect(() => {
    initAWS();
  }, []);

  if (!isConfigured)
    return (
      <main className={s.container}>
        <Animation className={s.background} />
        <div className={s.logo}>
          <LogoML6 className={s.logo} />
          <span>Vision AI</span>
        </div>
        <Waveform color={'#FF913A'} />
      </main>
    );

  return (
    <Suspense fallback={<div>...</div>}>
      <Authenticator.Provider>
        <MyRoutes tenant={envInfo.tenantId} />
      </Authenticator.Provider>
    </Suspense>
  );
}

export default App;
