export const LIST_PROCESSING_JOBS = `
      query q($status: String, $sortDirection: String, $startDate: String, $endDate: String,$tagValue: String,$tagKey: String) {
        listProcessingJobs(status: $status, sortDirection: $sortDirection, startDate: $startDate, endDate: $endDate ,tagValue: $tagValue , tagKey: $tagKey) {
          PK
          creation_datetime
          start_datetime
          stop_datetime
          status
          observation_id
          tags
          steps {
              name
              status
              metadata
              tags
              creation_datetime
              start_datetime
              stop_datetime
              index
              description
              model_id
            }
        }
      }
    `;

export const GET_PROCESSING_JOB = `query q($PK: ID!) {
          getProcessingJob(PK: $PK, SK:"INFO") {
            steps {
              name
              status
              metadata
              tags
              creation_datetime
              start_datetime
              stop_datetime
              index
              description
              model_id
            }
          PK
          creation_datetime
          start_datetime
          stop_datetime
          status
          observation_id
          }
        }`;
